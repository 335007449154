import React from "react";

const Data = () => {
  return (
    <div className="home__data  ">
      <h className="home__title ">Franklyn Umeh</h>

      <h3 className="home__subtitle"> DevOps Engineer | Cloud | Software Developer</h3>
      <p className="home__description">
        {/* Hello! Welcome to my website. My name is Chiemela. The fascination with
        how computers work drove me from Mechanical Engineering to Computer
        Science at the University of Houston, (Go Coogs! 🐾). Outside of work, I enjoy playing
        chess, working out, and attending music concerts. */}
        {/* I’m Chiemela, a Software Engineer with a passion for building scalable, 
   efficient solutions. My expertise spans a variety of practices,
    including CI/CD, DevOps, Linux systems Administration, automation,
     and cloud infrastructure. I enjoy solving complex problems and collaborating 
     with teams to deliver high-quality, reliable software.  */}
        {/* I’m Franklyn, a DevOps engineer with over 5 years of experience, dedicated to
         building scalable, resilient cloud solutions. My expertise includes CI/CD, Linux 
         systems administration, infrastructure management, automation, and monitoring. I thrive on solving complex challenges and 
        collaborating with teams to deliver high-quality, reliable software. */}

DevOps Engineer with over 4 years of experience building scalable, reliable, and secure systems across public clouds and
on- premises environments. Highly specialized in Kubernetes and Multi-Cloud environments (AWS, GCP, and Azure),
with expertise in Networking, Compute, Storage, Messaging and Streaming using Kafka, Security and Observability,
Cloud Cost Optimization, Infrastructure as Code (IaC) with Terraform, and CI/CD Pipelines. I have strong hands-on
experience with scripting languages like Python, Groovy, JSON, YAML, and Shell scripting.
      </p>
      {/* <a href="#contact" className="button button--flex center-button">
        <p>Say Hello</p>
      </a> */}
    </div>
  );
};

export default Data;
